import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Banner from "../components/Banner";
import { Container, Row } from 'react-bootstrap';

const NotFound = () => {
    return (
        <div id="innerBody" className="innerBody notfound-page">
            <Helmet>
                <title>Clínica dentária | Marta Gonçalves: Page not found.</title>
                <meta property="og:title" content={`Clínica dentária | Marta Gonçalves: Page not found.`} />
                <meta
                    name="description"
                    content="Clínica especialista na saúde oral garantindo qualidade de vida em Lisboa, Saldanha. Casos clínicos de cirurgias e tratamentos dentários de confiança."
                />
                <meta
                    property="og:description"
                    content="Clínica especialista na saúde oral garantindo qualidade de vida em Lisboa, Saldanha. Casos clínicos de cirurgias e tratamentos dentários de confiança." />

            </Helmet>
            <Banner titulo={"404"} image={"../assets/banner-servicos.jpg"} />
            <Container>
                <Row className='text-center d-block'><p>Página não encontrada</p>
                    <Link to={"/"} className="pill-btn" >Regresse à homepage</Link>
                </Row>
            </Container>
        </div>
    );
};

export default NotFound;