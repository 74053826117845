import gql from "graphql-tag";

const PAGINA_SOBRE_NOS_QUERY = gql`
query SobreNos{
  paginaSobreNos{
    data{
      id
      attributes{
        textBlock1
        textBlock2
        image1{data{attributes{url}}}
        image2{data{attributes{url}}}
        banner{
          data{
            attributes{
              url
            }
          }
        }
      }
    }
  }
}
`;

export default PAGINA_SOBRE_NOS_QUERY;