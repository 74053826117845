import gql from "graphql-tag";

const FINANCIAMENTO_QUERY = gql`
query PaginaplanoDeFinanciamento{
    planoDeFinanciamento{
      data{
        attributes{
          Titulo
          Content
          banner{data{attributes{url}}}
          }
        }
      }
    }`;

export default FINANCIAMENTO_QUERY;