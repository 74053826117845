import React from "react";
import { useQuery } from "@apollo/react-hooks";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";

import { Container, Row, Col} from 'react-bootstrap';

import { Link } from 'react-router-dom';


import Banner from "../components/Banner";

import SERVICOS_QUERY from "../queries/service/service";
import PAGINA_SERVICOS_QUERY from "../queries/service/service_page";


const Servicos = () => {
  const { data: dataR, error: errorR, loading: landingR } = useQuery(PAGINA_SERVICOS_QUERY);
  const { loading, error, data } = useQuery(SERVICOS_QUERY)

  if (loading || landingR) return <div className="loader"><img src="../assets/loader_MG.gif" alt="Loading&hellip;" width="100%" height="100%" /></div>
  if (error || errorR) return <p>Error :(</p>

  const servicosData = data.servicos.data;


  // nota: bannerServicos é a variavel que contem a imagem do banner de servicos.

  return (
    <div id="innerBody" className="innerBody servicos-page">
      <Helmet>
        <title>Marta Gonçalves especializada em tratamentos dentários.</title>
        <meta property="og:title" content={`Marta Gonçalves especializada em tratamentos dentários.`} />
        <meta
          name="description"
          content="Serviços da clínica contêm  ortodontia, diagnóstico 3D, anestesia sem dor, implantes, cirurgia dentária, ortodontopediatria e higiene oral."
        />
        <meta 
        property="og:description"        
        content="Serviços da clínica contêm  ortodontia, diagnóstico 3D, anestesia sem dor, implantes, cirurgia dentária, ortodontopediatria e higiene oral." />
      </Helmet>
      <Banner titulo={"Serviços"} image={dataR.paginaServico.data.attributes.banner.data.attributes.url} />
      <Container className="d-flex flex-column align-items-center">
        <Row className="full-width servicos-intro">
          <Row className="blue-bg">
            <ReactMarkdown>{dataR.paginaServico.data.attributes.Introducao}</ReactMarkdown>
          </Row>
          <Row className="servicos-intro-img">
            <Col>
              <img
              alt=""
                src={process.env.REACT_APP_BACKEND_URL + dataR.paginaServico.data.attributes.imagem1.data.attributes.url}
              />
            </Col>
            <Col>
              <img
              alt=""
                src={process.env.REACT_APP_BACKEND_URL + dataR.paginaServico.data.attributes.imagem2.data.attributes.url}
              />
            </Col>
            <Col>
              <img
              alt=""
                src={process.env.REACT_APP_BACKEND_URL + dataR.paginaServico.data.attributes.imagem3.data.attributes.url}
              />
            </Col>
          </Row>
        </Row>
        <Row className="servicos-list full-width">
          <Row>
            {servicosData.map((servico) => (
              <Col md={6} lg={4} key={servico.id} className="servico-item">
                <img src={process.env.REACT_APP_BACKEND_URL + servico.attributes.Imagem.data.attributes.url} alt={servico.attributes.Nome} className="servico-icon" />
                <h3 className="text-center">
                  {servico.attributes.Nome}
                </h3>
                <ReactMarkdown>
                  {servico.attributes.Descricao}
                </ReactMarkdown>
              </Col>
            ))}
          </Row>
          <Row>
            <Col className="d-flex justify-content-center">
              <Link className="pill-btn" to={{pathname: "/agendar"}}>
                Agendar
              </Link>
            </Col>
          </Row>
        </Row>
      </Container>
    </div>

  );
};

export default Servicos;