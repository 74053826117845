import gql from "graphql-tag";

const HOMEPAGE_QUERY = gql`
query Homepage {
  homepage {
    data {
      attributes {
        Titulo,
        Slider{
					... on ComponentSliderSlider {
              id
            	Imagem{data{attributes{url}}}
            	Texto
          }
        }
        blocosHomepage{
          ... on ComponentBlocoHomepageBlocoHomepage {
              id
              textoLinha1
              textoLinha2
            	imagem{data{attributes{url}}}
          }
        }
        sobreHomepage{
          texto
          imagem{data{attributes{url}}}
        }
        SabiaQue{
          ... on ComponentSabiaQueSabiaQue {
              Texto
            	Imagem{data{attributes{url}}}
          }
        }
        Testemunhos{
          ... on ComponentTestemunhosTestemunhos {
              Testemunho
            	Nome
            	Foto{data{attributes{url}}}
            
          }
        }
        agendarHomepage{
          Imagem{data{attributes{url}}}
        }
      }
    }
  }
}
`;

export default HOMEPAGE_QUERY;