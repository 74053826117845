import gql from "graphql-tag";

const CASOS_ALL_QUERY = gql`
query CasoSingle{
  casoClinicos{
    data{
      attributes{
        Titulo
        SubTitulo
        Slug
        imagem1{data{attributes{url}}}
        Servicos{data{attributes{Slug}}}
      }
    }
  }
}
  `;

export default CASOS_ALL_QUERY;