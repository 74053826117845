import gql from "graphql-tag";

const CASO_SINGLE_QUERY = gql`
query CasoSingle($slug: String!){
  casoClinicos(filters: { Slug:{eq: $slug}}){
    data{
      id
      attributes{
        Titulo
        Slug
        SubTitulo
        imagem1{data{attributes{url}}}
        imagem2{data{attributes{url}}}
        content1
        content2
      }
    }
  }
}
  `;

export default CASO_SINGLE_QUERY;