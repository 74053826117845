/* import React, { useState } from "react"; */
import { Helmet } from "react-helmet";

import { Container, Row, Col } from 'react-bootstrap';



const Agendar = () => {
  /* const [formData, setFormData] = useState({
    nome: "",
    email: "",
    telemovel: "",
    dia: "",
    motivo: "",
    horario: ""
  }) */

  /* const handleSubmit = (e) => {
    e.preventDefault();
    (async () => {
      // GET request using fetch with async/await
      const element = document.getElementById('get-request-async-await');
      const response = await fetch(`https://clinicadentariamg.com/PHPMailer/agendar.php/?nome=${formData.nome}&email=${formData.email}&telemovel=${formData.telemovel}&dia=${formData.dia}&horario=${formData.horario}&motivo=${formData.motivo}`);
      const data = await response.status;
      if (data === 200) {
        element.innerHTML = "<p class='text-center text-success p-0'>Mensagem enviada com sucesso!</p>";
        setFormData({
          nome: "",
          email: "",
          telemovel: "",
          dia: "",
          motivo: "",
          horario: ""
        });
      }
      else {
        element.innerHTML = "<p class='text-center text-danger'>Erro! Mensagem não enviada. Verifique os campos.</p>"
      }
    })()
  } */

  return (
    <div id="innerBody" className="innerBody contactos-page pt-lg-5 mt-lg-5">
      <Helmet>
        <title>Clínica Marta Gonçalves: Agendamentos perto de Saldanha.</title>
        <meta property="og:title" content={`Clínica Marta Gonçalves: Agendamentos perto de Saldanha.`} />
        <meta
          name="description"
          content="Agende uma consulta com a clínica Marta Gonçalves e visite-nos em Lisboa, Saldanha junto à Casa Da Moeda"
        />
        <meta
          property="og:description"
          content="Agende uma consulta com a clínica Marta Gonçalves e visite-nos em Lisboa, Saldanha junto à Casa Da Moeda." />
      </Helmet>
      <Container>
        <Row className="d-flex justify-content-center pt-lg-5 mt-lg-5">
          <h2 className="fs-1 text-center"><strong>AGENDE JÁ A SUA</strong><br />CONSULTA ONLINE!</h2>
        </Row>
        <Row>
          <Col lg={6} className="offset-lg-3 text-center">
            <p>Agende a sua consulta online através do Portal Paciente. <br />Clique no botão abaixo para aceder.</p>
            <a class="pill-btn" href="https://www.portalpaciente.pt/1907bb8f-47db-4c75-b072-614d8cf9c762/login" target="_blank" rel="noopener noreferrer">Agendar Aqui</a>
            {/* <form onSubmit={handleSubmit} className="contactos-form">
              <div className="d-flex">
                <input onChange={(e) => setFormData({ ...formData, motivo: e.target.value })} value={formData.motivo} type="text" name="motivo" placeholder="Motivo da consulta" required />
              </div>
              <div className="d-flex">
                <input onChange={(e) => setFormData({ ...formData, dia: e.target.value })} value={formData.dia} type="date" name="dia" placeholder="Dia" required />
              </div>
              <div className="d-flex">
                <input onChange={(e) => setFormData({ ...formData, horario: e.target.value })} value={formData.horario} type="time" name="horario" placeholder="Horário" required />
              </div>
              <div className="d-flex">
                <input onChange={(e) => setFormData({ ...formData, nome: e.target.value })} value={formData.nome} type="text" name="nome" placeholder="Nome" required />
                <input onChange={(e) => setFormData({ ...formData, telemovel: e.target.value })} value={formData.telemovel} type="tel" name="telemovel" placeholder="Telemovel" required />
              </div>
              <div>
                <input onChange={(e) => setFormData({ ...formData, email: e.target.value })} value={formData.email} type="email" name="email" placeholder="E-mail" required />
              </div>
              <div className="rgdp-block d-flex justify-content-center"><input type="checkbox" name="rgpd" required /><label htmlFor="rgpd">Concordo com a política de privacidade deste site</label></div>
              <div id="get-request-async-await">
              </div>
              <div className="text-center">
                <input type="submit" value="Enviar" />
              </div>
              <div id="get-request-async-await">
                <p className="total"></p>
              </div>
            </form> */}

          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Agendar;