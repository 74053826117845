import React from 'react';
import Carousel from "react-bootstrap/Carousel";
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';

const Slide = ({ sliderData, sliderName, sliderButton, sliderText }) => {
  const sliderTextContent = (sliderTextFinal) => {
    if (sliderTextFinal) {
      if (sliderButton) {
        return (
          <div className='myInnerSliderData'>
            <ReactMarkdown>{sliderTextFinal}</ReactMarkdown>
            <Link className="pill-btn text-white" to={{pathname: "/agendar"}}>
              Marque já a sua consulta
            </Link>
          </div>
        );
      }
      return (
        <div className='myInnerSliderData'>
          <ReactMarkdown>{sliderTextFinal}</ReactMarkdown>
        </div>
      );
    }
    if (sliderButton) {
      return (
        <div className='myInnerSliderData'>
          <Link className="pill-btn text-white" to={{pathname: "/agendar"}}>
              Marque já a sua consulta
            </Link>
        </div>
      );
    }
  }
  return (
    <Carousel fade="true" touch="true" variant="dark">
      {sliderData.map((slide) => (
        <Carousel.Item key={slide.id}>
          <img
            className="d-block w-100"
            src={process.env.REACT_APP_BACKEND_URL + slide.Imagem.data.attributes.url}
            alt="First slide"
          />
          {sliderTextContent(slide.Texto)}
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default Slide;