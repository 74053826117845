import React from "react";
import { useQuery } from "@apollo/react-hooks";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";

import { Container, Row, Col, Card } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


import Banner from "../components/Banner";

import PAGINA_SOBRE_NOS_QUERY from "../queries/sobreNos/contactos_page";
import EQUIPA_QUERY from "../queries/equipa/equipa";

const Sobre = () => {
  const { data: dataR, error: errorR, loading: landingR } = useQuery(EQUIPA_QUERY);
  const { loading, error, data } = useQuery(PAGINA_SOBRE_NOS_QUERY)

  if (loading || landingR) return <div className="loader"><img src="../assets/loader_MG.gif" alt="Loading&hellip;" width="100%" height="100%" /></div>
  if (error || errorR) return <p>Error :(</p>

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <div id="innerBody" className="innerBody sobre-page">
      <Helmet>
        <title>Clínica dentária | Marta Gonçalves: dentista em Lisboa.</title>
        <meta property="og:title" content={`Clínica dentária | Marta Gonçalves: dentista em Lisboa.`} />
        <meta
          name="description"
          content="Equipa clínica Marta Gonçalves em Lisboa, Saldanha especializados em ortodontia, cirurgia sem dor, implantes dentários, próteses dentárias."
        />
        <meta 
        property="og:description"        
        content="Equipa clínica Marta Gonçalves em Lisboa, Saldanha especializados em ortodontia, cirurgia sem dor, implantes dentários, próteses dentárias." />
      </Helmet>
      <Banner titulo={"SOBRE NÓS"} image={data.paginaSobreNos.data.attributes.banner.data.attributes.url} />
      <Container>
        <Row>
          <Col lg={6} className="d-flex flex-column justify-content-center text-block">
            <ReactMarkdown>
              {data.paginaSobreNos.data.attributes.textBlock1}
            </ReactMarkdown>
          </Col>
          <Col lg={6} className="img-block">
            <img
            alt=""
              src={process.env.REACT_APP_BACKEND_URL + data.paginaSobreNos.data.attributes.image1.data.attributes.url}
            />
          </Col>
        </Row>
        <Row className="d-flex reversed-col">
          <Col lg={6} className="img-block">
            <img
            alt=""
              src={process.env.REACT_APP_BACKEND_URL + data.paginaSobreNos.data.attributes.image2.data.attributes.url}
            />
          </Col>
          <Col lg={6}  className="d-flex flex-column justify-content-center text-block">
            <ReactMarkdown>
              {data.paginaSobreNos.data.attributes.textBlock2}
            </ReactMarkdown>
          </Col>
        </Row>
        <Row className="full-width equipa-block">
          <h2 className="text-center"><strong>A nossa</strong> equipa</h2>
          <Row className="half-bg">
            <Carousel 
            responsive={responsive}
            swipeable={true}
            draggable={true}
            showDots={true}
            containerClass="carousel-container"
            infinite={true}
            autoPlaySpeed={5000}
            arrows={false}
            >
              {dataR.equipas.data.map((membro) => (
                <Card key={membro.id}>
                  <img

                    src={membro.attributes.foto.data ? process.env.REACT_APP_BACKEND_URL + membro.attributes.foto.data.attributes.url : ""}
                    alt="team member"
                  />
                  <p><strong>{membro.attributes.Nome}</strong></p>
                  <p>{membro.attributes.Funcao}</p>
                </Card>
              ))}
            </Carousel>
          </Row>
        </Row>
      </Container>
    </div>
  );
};

export default Sobre;