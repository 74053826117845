import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";

import { Container, Row, Col } from 'react-bootstrap';

import Banner from "../components/Banner";

import PAGINA_CONTACTOS_QUERY from "../queries/contactos/contactos_page";
import loader from "../assets/loader_MG.gif";

const Contactos = () => {
  const [formData, setFormData] = useState({
    nome: "",
    email: "",
    telemovel: "",
    endereço: "",
    assunto: "",
    mensagem: "",
    rgpd: "",
  })

  const { loading, error, data } = useQuery(PAGINA_CONTACTOS_QUERY)

  if (loading) return <div className="loader"><img src={loader} alt="Loading&hellip;" width="100%" height="100%" /></div>
  if (error) return <p>Error :(</p>

  const handleSubmit = (e) => {
    e.preventDefault();
    (async () => {
      // GET request using fetch with async/await
      const element = document.getElementById('get-request-async-await');
      const response = await fetch(`https://clinicadentariamg.com/PHPMailer/contactos.php/?nome=${formData.nome}&email=${formData.email}&telemovel=${formData.telemovel}&endereço=${formData.endereço}&assunto=${formData.assunto}&mensagem=${formData.mensagem}`);
      const data = await response.status;
      if (data === 200){
        element.innerHTML = "<p class='text-center text-success p-0'>Mensagem enviada com sucesso!</p>";
        setFormData({
          nome: "",
          email: "",
          telemovel: "",
          endereço: "",
          assunto: "",
          mensagem: "",
          rgpd: "",
        });
      }
      else{
        element.innerHTML = "<p class='text-center text-danger'>Erro! Mensagem não enviada. Verifique os campos.</p>"
      }
    })()
  }

  return (
    <div id="innerBody" className="innerBody contactos-page">
      <Helmet>
        <title>Clínica dentária | Marta Gonçalves: Contactos das nossas instalações.</title>
        <meta property="og:title" content={`Clínica dentária | Marta Gonçalves: Contactos das nossas instalações.`} />
        <meta
          name="description"
          content="Entre em contacto com a clínica dentária Marta Gonçalves em Lisboa e faça uma marcação com os nossos especialistas. Consulta de rotina, implantes, limpeza."
        />
        <meta 
        property="og:description"        
        content="Entre em contacto com a clínica dentária Marta Gonçalves em Lisboa e faça uma marcação com os nossos especialistas. Consulta de rotina, implantes, limpeza." />

      </Helmet>
      <Banner titulo={"CONTACTOS"} image={data.paginaContacto.data.attributes.banner.data.attributes.url} />
      <Container>
        <Row>
          <Col lg={6} className="contactos-info">
            <div>
              <h4>Horário</h4>
              <div>
                <ReactMarkdown>{data.paginaContacto.data.attributes.horario}</ReactMarkdown>
              </div>

              <h4>APOIO AO CLIENTE</h4>
              <div className="phone">
                <ReactMarkdown>{data.paginaContacto.data.attributes.apoioAoCliente}</ReactMarkdown>
              </div>

              <h4>MORADA</h4>
              <div className="address">
                <ReactMarkdown>{data.paginaContacto.data.attributes.morada}</ReactMarkdown>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <form onSubmit={handleSubmit} className="contactos-form">
              <div className="d-flex">
                <input onChange={(e) => setFormData({ ...formData, nome: e.target.value })} value={formData.nome} type="text" name="nome" placeholder="Nome" required />
                <input onChange={(e) => setFormData({ ...formData, email: e.target.value })} value={formData.email} type="email" name="email" placeholder="E-mail" required />
              </div>
              <div className="d-flex">
                <input onChange={(e) => setFormData({ ...formData, telemovel: e.target.value })} value={formData.telemovel} type="tel" name="telemovel" placeholder="Telemovel" required />
                <input onChange={(e) => setFormData({ ...formData, endereço: e.target.value })} value={formData.endereço} type="text" name="endereço" placeholder="Endereço" required />
              </div>
              <div>
                <input onChange={(e) => setFormData({ ...formData, assunto: e.target.value })} value={formData.assunto} type="text" name="assunto" placeholder="Assunto" required />
                <textarea onChange={(e) => setFormData({ ...formData, mensagem: e.target.value })} value={formData.mensagem} type="text" name="mensagem" placeholder="Mensagem" required />
              </div>
              <div className="rgdp-block d-flex justify-content-center"><input type="checkbox" value={formData.rgpd} name="rgpd" required /><label htmlFor="rgpd">Concordo com a política de privacidade deste site</label></div>
              <div id="get-request-async-await">
              </div>
              <div className="text-center">
                <input type="submit" value="Enviar" />
              </div>
            </form>
          </Col>
        </Row>
      </Container>
      <Row>
        <iframe title="gmap" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12448.63938587926!2d-9.144061!3d38.737094!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe350a95653f3f02e!2sCl%C3%ADnica%20Dent%C3%A1ria%20Marta%20Gon%C3%A7alves!5e0!3m2!1spt-PT!2spt!4v1652107083514!5m2!1spt-PT!2spt" width="100%" height="600" allowFullScreen="" referrerPolicy="no-referrer-when-downgrade"></iframe>
      </Row>

    </div>
  );
};

export default Contactos;