import React from "react";

import { Routes, Route } from "react-router-dom";
import { Container, Row } from "react-bootstrap";

import Header from "../../components/Header";
import Homepage from "../../pages/Homepage"
import Sobre from "../../pages/Sobre";
import Servicos from "../../pages/Servicos";
import Contactos from "../../pages/Contactos";
import CasosClinicos from "../../pages/CasosClinicos";
import Caso from "../Caso";
import Plano from "../../pages/Financiamento";
import Privacidade from "../../pages/Privacidade";
import Footer from "../../components/Footer"
import Agendar from "../../pages/Agendar";
import NotFound from "../../pages/NotFound";

//<Route path="/article/:slug" element={<Article />} exact />
//<Route path="/category/:slug" element={<Category />} exact />

function App() {
  return (
    <div className="App">
      <Container fluid className="p-0">

        <Header />
        <Row id="body" className="body">
          <Routes>
            <Route path="/" element={<Homepage />} exact />
            <Route path="/sobre-nos" element={<Sobre />} exact />
            <Route path="/servicos" element={<Servicos />} exact />
            <Route path="/casos-clinicos" element={<CasosClinicos />} exact />
            <Route path="/casos-clinicos/:slug" element={<Caso />} exact />
            <Route path="/contactos" element={<Contactos />} exact />
            <Route path="/politica-privacidade" element={<Privacidade />} exact />
            <Route path="/plano-financiamento" element={<Plano />} exact />
            <Route path="/agendar" element={<Agendar />} exact />
            <Route path="*" element={<NotFound />} />
            
          </Routes>
        </Row>
        <Footer />
      </Container>
    </div>
  );
}

export default App;
