import gql from "graphql-tag";

const EQUIPA_QUERY = gql`
query Equipa{
  equipas{
    data{
      id
      attributes{
        foto{data{attributes{url}}}
        Nome
        Funcao
      }
    }
  }
}
`;

export default EQUIPA_QUERY;