import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Link } from "react-router-dom";

import { Container, Row, Col } from "react-bootstrap";

import FOOTER_QUERY from "../../queries/footer/footer.js";

//import logoBlack from "../../assets/logo_black.svg";
import logoWhite from "../../assets/logo_white.svg";
import logoFB from "../../assets/f-logo-rgb-white.svg";
import logoIG from "../../assets/ig-glyph-white.svg";
import ReactMarkdown from "react-markdown";

const Footer = () => {
  const { loading, error, data } = useQuery(FOOTER_QUERY)

  if (loading) return <p></p>
  if (error) return <p>Error :(</p>

  const headerAttributes = data.header.data.attributes;
  const headerNome = headerAttributes.Nome;
  //const headerLogoURL = process.env.REACT_APP_BACKEND_URL + headerAttributes.Logo.data.attributes.url;

  const footerAttributes = data.footer.data.attributes;
  const footerColunas = footerAttributes.Colunas;

  return (
    <footer id="footer" className="footer dark-blue-bg">
      <Container id="footer-top">
        <Row>
          {footerColunas.map((coluna) => (
            <Col key={coluna.id} id={'fc-' + coluna.id}>
              <h4>{coluna.Titulo}</h4>
              <ReactMarkdown>{coluna.Conteudo}</ReactMarkdown>
            </Col>
          ))}
          <Col>
            <h4>Informações</h4>
            <Link to={{ pathname: "/politica-privacidade" }}>Política de Privacidade</Link>
            <Link to={{ pathname: "/plano-financiamento" }}>Plano de Financiamento</Link>
          </Col>
        </Row>
      </Container>
      <Container id="footer-bottom credits">
        <Row>
          <Col className="social-list">
            <ul>
              <li><h4>Siga-nos:</h4></li>
              <li><a href="https://pt-pt.facebook.com/clinicadentariamg/"><img src={logoFB} alt="Facebook" width="16" height="16" /></a></li>
              <li><a href="https://www.instagram.com/clinicadentariamg/"><img src={logoIG} alt="Instagram" width="100%" height="100%" /></a></li>
            </ul>
          </Col>
          <Col className="text-center">© {new Date().getFullYear()} {headerNome} - Todos os direitos reservados.</Col>
          <Col className="powered-by">Proudly Powered by <a href="https://terastudio.pt/" target="_blank" rel="noreferrer" title="TERASTUDIO"><img id="TERASTUDIO-brand" src={logoWhite} alt="TERASTUDIO" width="100%" height="100%" /></a></Col>
        </Row>
      </Container>
      <div id="BTT-btn"><button onClick={() => {window.scrollTo({top: 0,behavior: 'smooth',})}}><span className="material-icons-round">keyboard_arrow_up</span></button></div>
    </footer>
  );
};

export default Footer;