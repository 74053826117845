import gql from "graphql-tag";

const PAGINA_SERVICOS_QUERY = gql`
query Servicos{
  paginaServico{
    data{
      id
      attributes{
        banner{data{attributes{url}}}
        Introducao
        imagem1{
          data{
            attributes{
              url
            }
          }
        }
        imagem2{
          data{
            attributes{
              url
            }
          }
        }
        imagem3{
          data{
            attributes{
              url
            }
          }
        }
      }
    }
  }
}
  `;

export default PAGINA_SERVICOS_QUERY;