import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Link } from "react-router-dom";

import Navigation from "../Navigation";

import HEADER_QUERY from "../../queries/header/header.js";
import { Row, Col } from "react-bootstrap";


const Header = ({ name, url }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const { loading, error, data } = useQuery(HEADER_QUERY)

  if (loading) return <p></p>
  if (error) return <p>Error :(</p>


  const headerAttributes = data.header.data.attributes;
  const headerNome = headerAttributes.Nome;
  const headerLogoURL = process.env.REACT_APP_BACKEND_URL + headerAttributes.Logo.data.attributes.url;


  return (
    <header className={`d-flex justify-content-between flex-wrap ${scrollPosition > 80 ? "bg-white" : ""}`}>
      <Row className="header">
        <Col lg={3} className="logo-block"><a href="/">
          <img
            src={headerLogoURL}
            alt={name}
          /></a></Col>
        <Col lg={9}>
          <Row className="justify-content-between">
            <Col md={12} lg={9} className="nav-block"><Navigation name={headerNome} url={headerLogoURL} /></Col>
            <Col md={12} xs={9} lg={3} className="btn-agendar d-flex justify-content-end align-items-center"><Link className="pill-btn"  to={{pathname:"/agendar"}}>Agendar Consulta</Link></Col>
          </Row>
        </Col>
      </Row>
    </header>
  );
};

export default Header;