import { useQuery } from "@apollo/react-hooks";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";

import { Container, Row, Col } from 'react-bootstrap';
import 'react-multi-carousel/lib/styles.css';


import Banner from "../components/Banner";

import FINANCIAMENTO_QUERY from "../queries/financiamento/financiamento";

const Financiamento = () => {
  const { loading, error, data } = useQuery(FINANCIAMENTO_QUERY)

  if (loading) return <div className="loader"><img src="../assets/loader_MG.gif" alt="Loading&hellip;" width="100%" height="100%" /></div>
  if (error) return <p>Error :(</p>

  return (
    <div id="innerBody" className="innerBody financiamento-page">
      <Helmet>
        <title>Clínica dentária | Marta Gonçalves: Financiamento no seu tratamento dentário.</title>
        <meta property="og:title" content={`Clínica dentária | Marta Gonçalves: Financiamento no seu tratamento dentário.`} />
        <meta
          name="description"
          content="Plano de financiamento da clínica Marta Gonçalves e os seus tratamentos assim como facilidades e métodos de pagamento, empréstimos e acordos."
        />
        <meta 
        property="og:description"        
        content="Plano de financiamento da clínica Marta Gonçalves e os seus tratamentos assim como facilidades e métodos de pagamento, empréstimos e acordos." />
      </Helmet>
      <Banner titulo={"Financiamento"} image={data.planoDeFinanciamento.data.attributes.banner.data.attributes.url}/>
      <Container>
        <Row>
          <Col>
            <ReactMarkdown>
              {data.planoDeFinanciamento.data.attributes.Content}
            </ReactMarkdown>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Financiamento;