import React from "react";
import { useParams } from "react-router";
import { useQuery } from "@apollo/react-hooks";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { Row, Col, Container } from "react-bootstrap";



import Banner from "../../components/Banner";

import CASO_SINGLE_QUERY from "../../queries/casosClinicos/caso_single";

import bgCasoSingle from "../../assets/bg_caso_single.jpg";

const Caso = () => {
  let { slug } = useParams();

  const { loading, error, data } = useQuery(CASO_SINGLE_QUERY, {
    variables: { slug: slug }
  })

  if (loading) return <div className="loader"><img src="../../assets/loader_MG.gif" alt="Loading&hellip;" width="100%" height="100%" /></div>
  if (error) return <p>Error :(</p>


  return (
    <div id="innerBody" className="innerBody casos-single-page">
      <Helmet>
        <title>{data.casoClinicos.data[0].attributes.Titulo} -  Dentista Marta Gonçalves </title>
        <meta
          name="description"
          content="Uma clinica dentária no coração de Lisboa. Saldanha Junto a casa da moeda. Dentista Marta Gonçalves"
        />
        <meta property="og:title" content={`${data.casoClinicos.data[0].attributes.Titulo} -  Dentista Marta Gonçalves `} />
        <meta property="og:image" content={process.env.REACT_APP_BACKEND_URL + data.casoClinicos.data[0].attributes.imagem1.data.attributes.url} />
        <meta 
        property="og:description"        
        content="Uma clinica dentária no coração de Lisboa. Saldanha Junto a casa da moeda. Dentista Marta Gonçalves" />

      </Helmet>
      <Banner titulo={data.casoClinicos.data[0].attributes.Titulo} image={bgCasoSingle} asset={true} />

      <Container>
        <Row className="justify-content-center d-flex nav-block">
          <Link className="text-center previous-btn" to="/casos-clinicos"><span className="material-icons-round">
            navigate_before
          </span> voltar</Link>
        </Row>
        <Row>
          <Col lg={6} className="img-block">
            <img
              alt=""
              src={process.env.REACT_APP_BACKEND_URL + data.casoClinicos.data[0].attributes.imagem1.data.attributes.url}
            />
          </Col>
          <Col lg={6} className="text-block">
            <ReactMarkdown>
              {data.casoClinicos.data[0].attributes.content1}
            </ReactMarkdown>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <ReactMarkdown>
              {data.casoClinicos.data[0].attributes.content2 ? data.casoClinicos.data[0].attributes.content2 : ""}
            </ReactMarkdown>
          </Col>

          <Col lg={6}>
            <img
              alt=""
              src={data.casoClinicos.data[0].attributes.imagem2.data ? process.env.REACT_APP_BACKEND_URL + data.casoClinicos.data[0].attributes.imagem2.data.attributes.url : ""}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Caso;