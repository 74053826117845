import gql from "graphql-tag";

const PAGINA_CONTACTOS_QUERY = gql`
query Contactos{
  paginaContacto{
    data{
      id
      attributes{
        banner{
          data{
            attributes{
              url
            }
          }
        }
        horario,
        apoioAoCliente
        morada
      }
    }
  }
}
`;

export default PAGINA_CONTACTOS_QUERY;