import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Helmet } from "react-helmet";

import { Container, Row, Col , Dropdown} from 'react-bootstrap';

import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'

import Banner from "../components/Banner";

import PAGINA_CASOS_QUERY from "../queries/casosClinicos/casos_page";
import SERVICOS_NOME_QUERY from "../queries/service/services_names";
import CASOS_ALL_QUERY from "../queries/casosClinicos/casos_all";


const CasosClinicos = () => {
  let [slug, setSlug] = useState({
    slug: "",
    contemSlug: false
  }
  );
  const isMobile = useMediaQuery({ query: '(max-width: 992px)' })

  const { loading: loadingR, error: errorR, data: dataR } = useQuery(PAGINA_CASOS_QUERY)
  const { loading: loadingC, error: errorC, data: dataC } = useQuery(CASOS_ALL_QUERY)
  const { loading, error, data } = useQuery(SERVICOS_NOME_QUERY)

  if (loading || loadingR || loadingC) return <div className="loader"><img src="../assets/loader_MG.gif" alt="Loading&hellip;" width="100%" height="100%" /></div>
  if (error || errorR || errorC) return <p>Error :(</p>


  let casoContent = (caso, index) => {
    if (slug.slug === "") {
      return (
        <Row className="single-post">
          <Col lg={6} >
            <img
              alt=""
              src={process.env.REACT_APP_BACKEND_URL + caso.attributes.imagem1.data.attributes.url}
            />
          </Col>
          <Col lg={6} className="post-info" >
            <Link to={`/casos-clinicos/${caso.attributes.Slug}`} className=""></Link>
            <h2 className="">{caso.attributes.Titulo}</h2>
            <p className="subtitle">{caso.attributes.SubTitulo}</p>
            <Link to={`/casos-clinicos/${caso.attributes.Slug}`} className="pill-btn">
              Saber +
            </Link>
          </Col>
        </Row>
      )
    } else {
      return (
        caso.attributes.Servicos.data.map((servico, index) => {
          if (servico.attributes.Slug.includes(slug.slug) === true) {
            return (
              <Row className="single-post" key={index}>
                <Col lg={6} >
                  <img
                    alt=""
                    src={process.env.REACT_APP_BACKEND_URL + caso.attributes.imagem1.data.attributes.url}
                  />
                </Col>
                <Col lg={6} className="post-info" >
                  <Link to={`/casos-clinicos/${caso.attributes.Slug}`} className=""></Link>
                  <h2 className="">{caso.attributes.Titulo}</h2>
                  <p className="subtitle">{caso.attributes.SubTitulo}</p>
                  <Link to={`/casos-clinicos/${caso.attributes.Slug}`} className="pill-btn">
                    Saber +
                  </Link>
                </Col>
              </Row>
            )
          }
          return ("");
        }
        )
      )

    }
  }

  return (
    <div id="innerBody" className="innerBody casos-page">
      <Helmet>
        <title>Clínica dentária | Marta Gonçalves: Casos clínicos.</title>
        <meta property="og:title" content={`Clínica dentária | Marta Gonçalves: Casos clínicos.`} />
        <meta
          name="description"
          content="Clínica especialista na saúde oral garantindo qualidade de vida em Lisboa, Saldanha. Casos clínicos de cirurgias e tratamentos dentários de confiança."
        />
        <meta 
        property="og:description"        
        content="Clínica especialista na saúde oral garantindo qualidade de vida em Lisboa, Saldanha. Casos clínicos de cirurgias e tratamentos dentários de confiança." />

      </Helmet>
      <Banner titulo={"CASOS CLÍNICOS"} image={dataR.paginaCasosClinico.data.attributes.banner.data.attributes.url} />
      <Container>
        <Row className="casos-filtro-block">
          {isMobile ?
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
              Serviço
            </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className={slug.slug === "" ? "active dropdown-item" : "dropdown-item"}>
                  <button onClick={() => (setSlug({ ...slug, slug: "" }))} >
                    <span>Todos</span></button>
                </Dropdown.Item>
                {data.servicos.data.map((caso, index) => {
                  return (
                    <Dropdown.Item key={index + 1} className={slug.slug === caso.attributes.Slug ? "active dropdown-item" : "dropdown-item"} >
                      <button onClick={() => (setSlug({ ...slug, slug: caso.attributes.Slug }))} >
                        <span> {caso.attributes.Nome}</span></button>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            :
            <ul className="lista-casos">
              <li className={slug.slug === "" ? "active" : ""}>
                <button onClick={() => (setSlug({ ...slug, slug: "" }))} >
                  <span>Todos</span></button>
              </li>
              {data.servicos.data.map((caso, index) => {
                return (
                  <li key={index + 1} className={slug.slug === caso.attributes.Slug ? "active" : ""} >
                    <button onClick={() => (setSlug({ ...slug, slug: caso.attributes.Slug }))} >
                      <span> {caso.attributes.Nome}</span></button>
                  </li>
                );
              })}
            </ul>
          }
        </Row>
        <Row className="full-width blue-bg casos-list-block">
          {dataC.casoClinicos.data.map((caso, index) => (
            <div key={index}>
              {casoContent(caso, index)}
            </div>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default CasosClinicos;