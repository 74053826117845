import React from "react";
import {Row} from 'react-bootstrap';


const Banner = ({ titulo, subtitulo , image , asset}) => {
  const imageURL = asset ? image : process.env.REACT_APP_BACKEND_URL + image  ;
  return (
    <Row className="banner-block text-center justify-content-center flex-column" style={{backgroundImage: `url(${imageURL})`}}>
       <h1>{titulo}</h1>
       <p>{subtitulo}</p>
    </Row>
  );
};

export default Banner;