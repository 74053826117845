import React from "react";
import { useQuery } from "@apollo/react-hooks";
import ReactMarkdown from "react-markdown";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { Container, Row, Col, Card } from 'react-bootstrap';

//import Slide from "../bootstrap/Slide";
//import Slide from "../components/Slide";
import Slide from "../bootstrap/ReactSlide";

import HOMEPAGE_QUERY from "../queries/homepage/homepage";
import SERVICOS_QUERY from "../queries/service/service";

const Homepage = () => {
  const { loading: loadingS, error: errorS, data: dataS } = useQuery(SERVICOS_QUERY)
  const { loading, error, data } = useQuery(HOMEPAGE_QUERY)

  if (loading || loadingS) return <div className="loader"><img src="../assets/loader_MG.gif" alt="Loading&hellip;" width="100%" height="100%" /></div>
  if (error || errorS) return <p>Error :(</p>

  const homepageData = data.homepage.data;
  const homepageAttributes = homepageData.attributes;

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <div id="innerBody" className="innerBody home-page">
      <Helmet>
        <title>Clínica dentária | Marta Gonçalves: Saldanha, Casa Da Moeda em Lisboa.</title>
        <meta
          name="description"
          content="Clínica dentária em Lisboa com ortodontia, diagnóstico 3D, anestesia sem dor, implantes, cirurgia dentária, ortodontopediatria e higiene oral."
        />
        <meta 
        property="og:description"        
        content="Clínica dentária em Lisboa com ortodontia, diagnóstico 3D, anestesia sem dor, implantes, cirurgia dentária, ortodontopediatria e higiene oral." />
      </Helmet>
      <Row className="intro-slider">
        <Slide sliderData={homepageAttributes.Slider} sliderName="carouselExampleIndicators" sliderButton={true} sliderText={"Cuidamos do seu sorriso com a tecnologia mais avançada"}/>
        </Row>
      <Container>
        <Row className="valores-block full-width blue-bg d-flex text-center">
          {homepageAttributes.blocosHomepage.map((bloco, index) => (
            <Col lg={4} key={index}>
              <img
            alt=""
                src={process.env.REACT_APP_BACKEND_URL + bloco.imagem.data.attributes.url}
              />
              <ReactMarkdown>
                {bloco.textoLinha1}
              </ReactMarkdown>
            </Col>
          ))}
        </Row>
        <Row className="sobre-block d-flex">
          <Col lg={6} className="d-flex flex-column justify-content-center text-block">
            <h2>Sobre nós&hellip;</h2>
            <ReactMarkdown>{homepageAttributes.sobreHomepage.texto}</ReactMarkdown>
          </Col>
          <Col lg={6} className="img-block">
            <img
            alt=""
              src={process.env.REACT_APP_BACKEND_URL + homepageAttributes.sobreHomepage.imagem.data.attributes.url}
            />
          </Col>
        </Row>
        <Row className="full-width servicos-block">
          <h2><span>Os nossos</span> serviços&hellip;</h2>
          <Row className="blue-bg">
            <ul>
              {dataS.servicos.data.map((servico, index) => (
                <li key={index}>
                  <img
                  alt=""
                    src={process.env.REACT_APP_BACKEND_URL + servico.attributes.Logo.data.attributes.url}
                  />
                  <h4>{servico.attributes.Nome}</h4>
                </li>
              ))}
            </ul>
          </Row>
        </Row>
        <Row className="testemunhos-block">
          <Row>
            <h2><span>A sua opinião</span> é importante!</h2>
          </Row>
          <Row>
            <Carousel
              responsive={responsive}
              swipeable={true}
              draggable={true}
              showDots={true}
              containerClass="carousel-container"
              infinite={true}
              autoPlaySpeed={5000}
              arrows={false}
            >
              {homepageAttributes.Testemunhos.map((testemunho, index) => (
                <Card key={index}>
                  <p className="testemunho-txt">{testemunho.Testemunho}</p>
                  <div className="testemunho-foto">
                    <img
                      src={testemunho.Foto.data != null ? process.env.REACT_APP_BACKEND_URL + testemunho.Foto.data.attributes.url : ""}
                      alt={testemunho.Nome.charAt(0)}
                    /></div>
                  <p className="testemunho-nome">{testemunho.Nome}</p>
                </Card>
              ))}
            </Carousel>
          </Row>
        </Row>
        <Row className="full-width sabia-block">
          <Row>
            <h2>Sabia que&hellip;</h2>
          </Row>
          {homepageAttributes.SabiaQue.map((sabiaque, index) => (
            <Row key={index} style={{ backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL + sabiaque.Imagem.data.attributes.url})` }} className="sabia-single">
              <Col lg={6}><ReactMarkdown>{sabiaque.Texto}</ReactMarkdown></Col>
            </Row>
          ))}
        </Row>
        <Row className="sobre-block d-flex">
          <Col lg={6} className="d-flex flex-column justify-content-center text-block">
            <h2><span>AGENDE JÁ A SUA</span> CONSULTA ONLINE!</h2>
            <div className="d-flex justify-content-center">
              <Link className="pill-btn" to={{ pathname: "/agendar" }}>
                Agendar
              </Link>
            </div>
          </Col>
          <Col lg={6} className="img-block">
            <img
            alt=""
              src={process.env.REACT_APP_BACKEND_URL + homepageAttributes.agendarHomepage.Imagem.data.attributes.url}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Homepage;