import { useQuery } from "@apollo/react-hooks";
import ReactMarkdown from "react-markdown";

import { Container, Row, Col } from 'react-bootstrap';
import 'react-multi-carousel/lib/styles.css';
import { Helmet } from "react-helmet";

import Banner from "../components/Banner";

import PRIVACIDADE_QUERY from "../queries/privacidade/privacidade";

const Privacidade = () => {
  const { loading, error, data } = useQuery(PRIVACIDADE_QUERY)

  if (loading) return <div className="loader"><img src="../assets/loader_MG.gif" alt="Loading&hellip;" width="100%" height="100%" /></div>
  if (error) return <p>Error :(</p>

  return (
    <div id="innerBody" className="innerBody financiamento-page">
      <Banner titulo={"Politica de Privacidade"} image={data.politicaDePrivacidade.data.attributes.banner.data.attributes.url}/>
      <Helmet>
        <title>Clínica dentária | Marta Gonçalves: Política de privacidade.</title>
        <meta property="og:title" content={`Clínica dentária | Marta Gonçalves: Política de privacidade.`} />
        <meta
          name="description"
          content="Consulte a nossa política de privacidade, valorizamos e respeitamos os seus dados e os seus direitos na clínica dentária Marta Gonçalves."
        />
        <meta 
        property="og:description"        
        content="Consulte a nossa política de privacidade, valorizamos e respeitamos os seus dados e os seus direitos na clínica dentária Marta Gonçalves." />
      </Helmet>
     <Container>
        <Row>
          <Col>
            <ReactMarkdown>
              {data.politicaDePrivacidade.data.attributes.Content}
            </ReactMarkdown>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Privacidade;