import React, { useState } from "react";
import { NavLink} from 'react-router-dom'

import {  Navbar, Nav } from "react-bootstrap";


const Navigation = ({ name, url }) => {
  const [estadoMenu, setEstadoMenu] = useState(false);

  return (
    <Navbar>
      <button 
      className={`toggle ${ estadoMenu ? "active" : ""}`} 
      onClick={() => estadoMenu ? setEstadoMenu(false) : setEstadoMenu(true)}
      />
        <Nav className={`mr-auto dark-blue-bg ${ estadoMenu ? "open active" : ""}`}>
          <ul>
          <li><NavLink to={{pathname: "/sobre-nos"}} onClick={() => estadoMenu ? setEstadoMenu(false) : setEstadoMenu(true)} className="nav-link" >Sobre</NavLink></li>
          <li><NavLink to={{pathname: "/servicos"}} onClick={() => estadoMenu ? setEstadoMenu(false) : setEstadoMenu(true)} className="nav-link">Serviços</NavLink></li>
          <li><NavLink to={{pathname: "/casos-clinicos"}} onClick={() => estadoMenu ? setEstadoMenu(false) : setEstadoMenu(true)} className="nav-link">Casos Clínicos</NavLink></li>
          <li><NavLink to={{pathname: "/contactos"}} onClick={() => estadoMenu ? setEstadoMenu(false) : setEstadoMenu(true)}  className="nav-link">Contactos</NavLink></li>
          </ul>
        </Nav>
    </Navbar>
  );
};

export default Navigation;