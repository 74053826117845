import gql from "graphql-tag";

const PAGINA_CASOS_QUERY = gql`
query paginaCasosClinicos{
  paginaCasosClinico{
    data{
      attributes{
        banner{data{attributes{url}}}
      }
    }
  }
}
  `;

export default PAGINA_CASOS_QUERY;