import gql from "graphql-tag";

const PRIVACIDADE_QUERY = gql`
query PaginaPoliticaDePrivacidade{
    politicaDePrivacidade{
      data{
        attributes{
          Titulo
          Content
          banner{data{attributes{url}}}
          }
        }
      }
    }`;

export default PRIVACIDADE_QUERY;